import React from "react";
import { Link } from "gatsby";

const Table = ({ name, data }) => (
  <div className="overflow-x-auto relative mb-12">
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <caption className="mb-4 text-orange-600">{name}</caption>
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="py-3 px-6">
            Course Date
          </th>
          <th scope="col" className="py-3 px-6">
            Private Room
          </th>
          <th scope="col" className="py-3 px-6">
            Double Room
          </th>
          <th scope="col" className="py-3 px-6">
            Triple Room
          </th>
          <th scope="col" className="py-3 px-6">
            Registration
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => {
          return (
            <tr
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              key={item.dates}
            >
              <th
                scope="row"
                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
              >
                {item.dates}
              </th>
              <td className="py-4 px-6">{item.private}</td>
              <td className="py-4 px-6">{item.shared}</td>
              <td className="py-4 px-6">{item.triple}</td>
              <td className="py-4 px-6">
                <Link to="/bookings" className="text-orange-600">
                  Enquire Now
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);

export default Table;
